.navbar {
    background: #040508;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 10;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 8px 24px;
    margin:10px;
    padding:10px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 40px;
    
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.1rem;
    height: 100%;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
    

  }
  
  .nav-item {
    height: 80px;
   top:0;
    border-bottom: 10px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #f00946;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 980px) {
    .NavbarItems {
      padding:10px;
      position: sticky;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 50vh;
      position: absolute;
      top: 90px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #040404;
 
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;

      
    }
  
    .nav-links {
      text-align: center;
      padding: -0px;
      width: 100%;
  
      display: table;
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
     
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      padding: 10px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
    }
  }
  