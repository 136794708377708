




.track_desp{
  align-items: center;
   
  display: flex;

  position:sticky;
  margin: auto;
 
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom:20px;
  padding-top:20px;
  text-align: center;
  max-width: 150%;
  flex-basis: 100%;
  justify-content: center;
   

 

}
.react-player {
  align-items: center;
   
  display: flex;

  position:sticky;
  margin: auto;
 
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  max-width: 150%;
  flex-basis: 100%;
  justify-content: center;
  
 

  
  
  
}


  @media screen and (max-width: 768px) {

    .react-player {
      align-items: center;
   
      display: flex;
  
      position:sticky;
      margin: auto;
     
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
      max-width: 110%;
      flex-basis: 100%;
      justify-content: center;
     
  
       
    
        
        
        
      }
      .track_desp{
        align-items: center;
        margin:20px;
        padding:10px;
        max-width: 50%;
        max-height: 450px; 
        margin-right: 300px;
        margin-left: 230px;
        display: flex;
        justify-content: center;
        position:sticky;
        margin: auto;
       
        padding-right: 30px;
        padding-left: 30px;
        text-align: center;
        max-width: 100%;
        flex-basis: 100%;
       
       
    
     
    
    }


  }

  @media screen and (max-width: 991px) {
    .container {
      padding-right: 60px;
      padding-left: 30px;
     
    }
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-bottom: 65px;
    }
  
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  