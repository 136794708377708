.bookings{
    display: flex;
    justify-content: center;
    position:sticky;
}
.footer-link-wrapper {
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:10px;
    padding-bottom:10px
    
  }

  .form > label
  {
    
    float: left;
    clear: right;
    display: inline-block; width: 245px; text-align: left;
   
  }
  
  .form > input
  {
    float: right;
    clear: left;
    display: inline-block; width: 345px; text-align: right
  }

  .input-wrapper {
  display: inline-block;
  text-align: left;
}
@media screen and (max-width:768px) {

    .base-template {
        padding: 40px 15px;
        text-align: center;
        color: #060a84;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        width: 100%;

}
.request-form-container {
    padding: 0.5em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    

    .messagebox{
        width:  16.76em ; 
        height: 10em;
        margin-right: 1em;
        margin-top: 0.5em;
        resize: none;
    }
    .inputbox{
        width:  16.76em ; 
        height: 4em;
        margin-right: 1em;
        margin-top: 0.5em;
        resize: none;
    }}
}
@media screen and (max-width: 991px) {
    .container {
      padding-right: 60px;
      padding-left: 30px;
     
    }
  }


.body {
    padding-top: 50px;
}

.base-template {
    padding: 40px 15px;
    text-align: center;
    color: #060a84;
    display: flex;
    justify-content: center;
    align-items: center;
}
.a {
    color: #060a84;
}
.formLabel {
    color: #060a84;
   
}
.request-form-container {
    padding: 0.5em;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.successMessage {
    text-align: center;
}
.submitFormContainer {
    margin-top: 1.6em;
}
.sdk-eula-display {
    width: 100%;
    float: left;
    margin-right: auto;
    margin-left: auto;
}

.error {
    color: red;
    border:2px solid red;
}

.request-form-container{
    width: 400px;
    margin:  auto;
    text-align: left;
}

.messagebox{
    width:  16.76em ; 
    height: 4em;
    margin-right: 1em;
    margin-top: 0.5em;
    resize: none;
}
.inputbox{
    width:  16.76em ; 
    height: 2em;
    margin-right: 1em;
    margin-top: 0.5em;
    resize: none;
}